/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

a,
button {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

p,li {
  line-height: 160%;
}

a:hover {
  text-decoration: none;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}


a.nav-link.active {
  color: #007bff !important;
  font-weight: 800;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}


nav.navbar {
  padding: 0%;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
  background-color: rgba(255, 255, 255);
  box-shadow: 0px 1px 7px rgb(172, 172, 172);
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}

a.nav-link:hover {
  color: #1455a6 !important;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
}


nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid black;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid black;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: black;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a.nav-link {
  color: #383838;
  font-weight: 600;
  margin: 0.5rem 1rem;
  padding: 10px;
}

.carousel-image-container {
  position: relative;
}

.carousel-image-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  /* Black color with 50% opacity */
  z-index: 1;
}


.carousel-caption {
  position: relative;
  z-index: 2;
  bottom: 9rem !important;
}

.carousel-caption h1 {
  font-size: 5rem;
  font-weight: 800;
}

.carousel-caption p {
  font-size: 2rem;
  font-weight: 400;
}

.underline-div {
  width: 10%;
  height: 5px;
  background-color: #007bff
}

p.lh-base {
  width: 70%;
}


.card-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.card-title.h5 {
  margin-top: 1rem;
}


.about-container-text{
  width: 80%;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .col-md-6 {
    width: 100% !important;
    margin-bottom: 2rem;
  }

  .underline-div{
    width: 35%;
  }

  img.img-fluid {
    width: 100%;
  }

}

@media only screen and (max-width: 600px) {

  nav.navbar.navbar-expand-lg.navbar-light {
    padding: 0%;
  }

  .carousel-caption h1 {
    font-size: 3rem;
  }

  .carousel-caption p {
    font-size: 1.5rem;
  }

  .underline-div {
    width: 50%;
  }

  p.lh-base {
    width: 90%;
  }

  .m-2.mb-5.card {
    margin-bottom: 1rem !important;
  }

  .px-5.col-md-6 {
    text-align: center !important;
    padding: 2rem 1rem 1rem 1rem !important;
  }

  .offer-icons{
    margin-right: 1rem;
  }

  .logoimg{
    width: 67% !important;
    margin-bottom: 2rem;
  }

  #my-2{
    margin-top: 2rem;
  }

  .text-center.achievements.row {
    flex-direction: column;
}

.achievements>.col{
  margin-bottom: 1rem;
}

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}